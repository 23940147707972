<template>
  <div id="subpage-content">
    <ProductDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarBenztruck") }}</h1>
        <div id="int-about-text">
          <img
            height="100"
            width="150"
            :src="getImageUrlbyName('Serv5')"
          />
          <span id="pad" v-html="$t('message.ProblemForm')"></span>
          <span id="pad" v-html="$t('message.ProblemText1')"></span>
          <span id="pad" v-html="$t('message.ProblemText2')"></span>
          <span id="pad" v-html="$t('message.ProblemSolution')"></span>
          <span id="pad" v-html="$t('message.ProblemSolutionText1')"></span>
          <span id="pad" v-html="$t('message.ProblemSolutionText2')"></span>
          <span id="pad" v-html="$t('message.ProblemSolutionText3')"></span>
          <span id="pad" v-html="$t('message.ProblemSolutionText4')"></span>
          <span id="pad" v-html="$t('message.ProblemSolutionText5')"></span>
        </div>
        <p></p>
        <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/wjM306HjVW4"
        frameborder="0"
        allowfullscreen=""
        id="iframe"
      ></iframe>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/LwrJw0AijZE"
        frameborder="0"
        allowfullscreen=""
        id="iframe"
      ></iframe>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn"
                >{{ $t("message.BuyService") }}
              </v-btn>
              <div id="history-card-text">
                {{ $t("message.MakeRequest") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import ProductDrawer from "../../../views/Navigations/Products/ProductDrawer";
import Feedback from "../../../components/Feedback";
export default {
  name: "Integration",
  components: {
    ProductDrawer,
    Feedback,
  },
};
</script>